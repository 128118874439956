import React, { Component } from 'react';

class About extends Component {
  render() {
    return (
      <h1>This is the about page</h1>
    )
  }
}

export default About;